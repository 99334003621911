<template>
	<v-text-field
		:type="state.showPassword ? 'text' : state.type"
		:placeholder="props.placeholder"
		variant="underlined"
		:focused="props.focused"
		:disabled="props.disabled"
		:model-value="props.modelValue"
		:error-messages="props.errrMessage"
		:rules="props.rules"
		@update:model-value="updateValue"
	>
		<template
			v-if="props.label"
			#label
		>
			<div class="required_label">
				<span class="v-text-field-lable">{{ props.label }}</span>
				<span
					v-if="props.required"
					class="text-red-200 ml-[2px]"
					>*</span
				>
			</div>
			<span
				v-if="!props.required"
				class="v-text-field-optional"
				>(optional)</span
			>
		</template>

		<template
			v-if="props.type === 'password' && props.modelValue"
			#append-inner
		>
			<span
				class="show-password-button"
				@click="updateType"
				>{{ $t("components.text_field.show_password") }}</span
			>
		</template>
	</v-text-field>
</template>

<script lang="ts" setup>
/**
 * @deprecated use TextInput from @tengiva/components
 */

type InputType = "text" | "password" | "email";

interface TextFieldProps {
	modelValue: string | null;
	required?: boolean;
	label?: string;
	placeholder?: string;
	type?: InputType;
	disabled?: boolean;
	focused?: boolean;
	errrMessage?: string;
	rules?: any;
}
const props = defineProps<TextFieldProps>();

interface TextFiledState {
	showPassword: boolean;
	type: InputType;
}

const state = reactive<TextFiledState>({
	showPassword: false,
	type: props?.type || "email",
});
const emits = defineEmits(["update:modelValue"]);

const updateValue = (value: string) => emits("update:modelValue", value);
const updateType = () => (state.showPassword = !state.showPassword);
</script>

<style lang="scss" scoped>
// Customized input outline
.v-input {
	:deep(.v-field) {
		@apply border-[4px] border-[#ffffff];
	}

	// Padding equals border with for field in order to vertically align child elements
	:deep(.v-input__details) {
		@apply px-[4px] text-left;
	}

	:deep(.v-field__outline::before) {
		border-image: none;
		@apply border-neutral-200 opacity-100;
	}

	:deep(.v-field__outline::after) {
		border-width: 0 0 1px;
	}

	:deep(.v-field--focused) {
		box-shadow: 0px 0px 3px 1px #0c7ce6;
		.v-field__outline::after {
			transform: scaleX(0);
		}
	}

	:deep(.v-field--disabled) {
		.v-field__outline::before {
			border-image: none;
		}
	}

	:deep(.v-field--error) {
		.v-field__outline::before {
			@apply border-red-200;
		}
	}
}

// Customized input label
.v-input {
	:deep(.v-label) {
		width: 100%;
		opacity: 1;
		display: flex;
		justify-content: space-between;
		.v-text-field-lable {
			@apply text-[16px] text-neutral-700;
		}

		.v-text-field-optional {
			@apply text-[14px] text-neutral-500;
		}
	}
}

// Customized input bottom message
.v-input {
	:deep(.v-messages__message) {
		font-size: 14px;
	}
	:deep(.show-password-button) {
		@apply text-[14px] leading-[20px] text-neutral-700 cursor-pointer;
	}
}
</style>
